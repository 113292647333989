<template>
  <section class="addform">
    <v-container class="container-custom">
      <v-row>
        <v-col md="6" cols="6">
          <h2 class="mb-5 mt-5">Add Menu</h2>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid" lazy-validation class="form-section">
        <v-row>
          <v-col md="12" cols="12" class="pb-0 pt-0">
            <strong>Menu</strong>
          </v-col>
          <v-col md="12" class="pb-0">
            <v-text-field
              class="inputbox"
              v-model="menuName"
              label="Menu Name"
              @keyup.enter.stop="validate"
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="12" cols="12" class="pb-0 pt-0">
            <strong>Link To</strong>
          </v-col>
          <v-col md="12" class="pb-0">
            <v-text-field
              class="inputbox"
              v-model="link"
              label="Link To"
              @keyup.enter.stop="validate"
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="12" cols="12" class="pb-0 pt-0">
            <strong>Menu Position</strong>
          </v-col>
          <v-col md="12" class="pb-0">
            <v-select
              :items="menuPositions"
              class="inputbox"
              v-model="menuPosition"
              label="Menu Position"
              @keyup.enter.stop="validate"
              outlined
            ></v-select>
          </v-col>
            <v-col md="12" cols="12" class="pb-0 pt-0">
            <strong>Menu Status</strong>
          </v-col>
          <v-col md="12" class="pb-0">
            <v-select
              :items="statuss"
              class="inputbox"
              v-model="status"
              label="Menu Status"
              item-text="name"
              item-value="value"
              @keyup.enter.stop="validate"
              outlined
            ></v-select>
          </v-col>

          <v-col md="12" cols="12" class="pb-0 pt-0" v-if="menus.length != 0">
            <strong>Sub Menu Of</strong>
          </v-col>
          <v-col md="12" class="pb-0" v-if="menus.length != 0">
            <v-select
              :items="menus"
              class="inputbox"
              v-model="connectedTo"
              label="Sub Menu Of"
              item-text="menuName"
              item-value="_id"
              @keyup.enter.stop="validate"
              outlined
            ></v-select>
          </v-col>

          <v-col md="12" cols="12" class="submitbtn mb-10">
            <v-btn
              :disabled="!valid"
              large
              @click="validate"
              v-if="!submitloading"
            >
              Save
            </v-btn>

            <v-btn class="submitbutton btn-dark" v-if="submitloading">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddBlog",

  data: () => ({
    valid: true,
    menuName: undefined,
    submitloading: false,
    connectedTo: undefined,
    menus: [],
    link: undefined,
    statuss:[{name:"Active",value:true},{name:"Deactive",value:false}],
    status:undefined,
    menuPosition: undefined,
    menuPositions: ["header", "footer"],
  }),
  methods: {
    ...mapActions(["postMenu", "getMenus", "getMenu"]),

    async validate() {
      if (this.$refs.form.validate()) {
        this.submitloading = true;
        let data = {};
        if (this.menuName != undefined) {
          data.menuName = this.menuName;
        }
        if (this.$route.params.id != undefined) {
          data._id = this.$route.params.id;
        }
        if (this.connectedTo != undefined) {
          data.connectedTo = this.connectedTo;
        }
        if (this.menuPosition != undefined) {
          data.menuPosition = this.menuPosition;
        }
        if (this.status != undefined) {
          data.status = this.status;
        }
        if (this.link != undefined) {
          data.link = this.link;
        }

        await this.postMenu(data);
        this.menus = this.allMenus.results;
        this.submitloading = false;
        this.$router.push("/business/menus");
      }
    },
  },
  computed: {
    ...mapGetters(["allMenus", "allMenu"]),
  },
  async created() {
    await this.getMenus();
    this.menus = this.allMenus.results;
    if (this.$route.params.id != undefined) {
      await this.getMenu(this.$route.params.id);
      this.menuName = this.allMenu.results.menuName;
      this.link = this.allMenu.results.link;
      this.connectedTo = this.allMenu.results.connectedTo;
      this.menuPosition = this.allMenu.results.menuPosition;
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.marBtmMinus5 {
  margin-bottom: -20px;
}
.submitbtn {
  text-align: left;
  color: #fff;
  display: flex;
  .v-btn {
    width: 250px;
  }
  .v-btn {
    color: #fff;
  }
  .btn-cancel {
    background: #f4f4f4 !important;
    color: #000 !important;
  }
}
.v-btn {
  min-height: 50px;
}
.relBox {
  position: relative;
}
.imageLoop {
  margin-top: 10px;
  position: relative;
  .v-icon {
    position: absolute;
    background: black;
    color: #fff !important;
    right: -10px;
    top: -10px;
    border-radius: 50%;
  }
  img {
    width: 80px;
    height: 85px;
    object-fit: contain;
    border: 1px solid #d1cccc;
  }
}
.submitbtn {
  text-align: left;
  margin-left: 0 !important;
}

.nextbtn {
  margin-left: 10px;
}

h3 {
  margin-bottom: 20px;
}
</style>
